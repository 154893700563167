.proporcao-info-modal {
  .ant-modal-header {
    padding: 16px;
  }
  .ant-modal-body {
    padding: 16px;
  }
  table {
    border-collapse: collapse;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 6px;
  }
  table th,
  table td {
    border: 1px solid #ccc;
    padding: 2px 3px;
    font-size: 11px;
  }
  table thead th {
    background: @primary;
    color: #fff;
    cursor: default;
    font-size: 12px;
  }
  table tbody tr:nth-child(2n) td {
    background-color: #fbfbfb;
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;