.produto-table {
  .ant-table table {
    table-layout: fixed;
  }
  .ant-table .ant-table-title {
    padding: 10px 11px;
  }
  .ant-table .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    background-color: #f2f2f2;
    padding: 8px 7px;

    .th-small-label {
      font-size: 12px;
      letter-spacing: -0.2px;
      margin-right: -2px;
    }

    .ant-table-column-sorter {
      margin-left: 5px;
    }
  }
  .ant-table-thead > tr > th .anticon-filter,
  .ant-table-thead > tr > th .ant-table-filter-icon {
    margin-left: 5px;
  }
  .ant-table-thead > tr > th .ant-table-column-sorter + .anticon-filter {
    margin-left: 2px;
  }
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-footer {
    padding: 8px 7px;
  }
  .ant-table-tbody > tr:not(.produto-table--row__disabled):hover > td {
    background: #edf8fc;
  }
  .ant-table-tbody > &--row {
    cursor: default;
    > td {
      font-size: 13px;
    }
    &__odd {
      background-color: #fafafa;
    }
    &__danger {
      background-color: lighten(#ac1a22, 52%);
    }
    &__campanha {
      background-color: lighten(#33af1a, 53%);
    }
    &__invalid {
      background-color: lighten(#f4c133, 35%);
    }
    &__selected {
      background-color: #4a87c9;
      > td {
        color: #fff;
      }
    }
    &__disabled {
      background-color: #fdfdfd !important;
      color: #bebebe !important;
      cursor: default !important;
    }
    .actions {
      .ant-btn {
        padding-left: 6px;
        padding-right: 6px;
      }
      .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 4px;
      }
    }
  }

  &__compact {
    .ant-table .ant-table-thead > tr > th,
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 5px !important;
      margin-right: -2px;
    }
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
      font-size: 11px;
      padding: 6px 4px !important;
    }
    .ant-table-footer {
      padding: 0;
    }
  }

  &__loading {
    min-height: 366px;
  }

  .wrapped-value {
    min-width: 100%;
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;