.chooser-modal {
  .ant-spin-nested-loading {
    min-height: 416px;
  }
  .ant-modal-header {
    padding: 12px 16px;
    cursor: default;
  }
  .ant-modal-body {
    padding: 18px 16px;
  }
  .ant-modal-close-x {
    width: 47px;
    height: 47px;
    line-height: 47px;
    cursor: pointer;
  }
  .chooser-table-wrapper {
    .ant-form {
      display: flex;
    }
    .ant-form-inline .ant-form-item {
      display: block;
      margin: 0;

      .ant-form-item-control-wrapper {
        display: block;
      }
    }
    .ant-form-inline .ant-form-item + .ant-form-item {
      margin-left: 9px;
    }
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;