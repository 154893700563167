.chooser-table {
  &--actions {
    .ant-btn + .ant-btn {
      margin-left: 6px;
    }
  }
  .ant-pagination-simple .ant-pagination-slash {
    margin-right: 3px;
  }
  .ant-table-pagination {
    margin: 16px 0 8px;
  }
  .ant-table table {
    table-layout: fixed;
  }
  .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
    width: 100%;
  }
  .ant-table .ant-table-title {
    padding: 10px 11px;
  }
  .ant-table .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th {
    background-color: #f2f2f2;
    padding: 8px 6px;

    .th-small-label {
      font-size: 12px;
      letter-spacing: -0.2px;
      margin-right: -2px;
    }

    .ant-table-column-sorter {
      margin-left: 5px;
    }
  }
  .ant-table-thead > tr > th .anticon-filter,
  .ant-table-thead > tr > th .ant-table-filter-icon {
    margin-left: 5px;
  }
  .ant-table-thead > tr > th .ant-table-column-sorter + .anticon-filter {
    margin-left: 2px;
  }
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-footer {
    padding: 8px 6px;
  }
  .ant-table-tbody > tr:not(.chooser-table--row__disabled):hover > td {
    background: #b3ddf9;
  }
  .ant-table-tbody > &--row {
    cursor: pointer;
    > td {
      font-size: 13px;
    }
    &__odd {
      background-color: #fafafa;
    }
    &__danger {
      background-color: lighten(#ac1a22, 52%);
    }
    &__success {
      background-color: #c2f5b9;
    }
    &__selected {
      background-color: #4a87c9;
      > td {
        color: #fff;
      }
    }
    &__disabled {
      background-color: #fdfdfd !important;
      color: #bebebe !important;
      cursor: default !important;
    }
    .actions {
      .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 5px;
      }
    }
  }

  &__compact {
    .ant-table .ant-table-thead > tr > th,
    .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
    .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 5px !important;
      margin-right: -2px;
    }
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
    .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
    .ant-table-footer {
      font-size: 11px;
      padding: 6px 5px !important;
    }
  }

  &__loading {
    min-height: 366px;
  }

  .wrapped-value {
    min-width: 100%;
  }

  .filter-dropdown {
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;