.App {
  .app-content {
    .ant-form-vertical {
      .ant-form-item-label {
        padding: 0 0 6px;
        line-height: 1;
      }
      .ant-form-item {
        margin-bottom: 5px;
      }
      .ant-form-extra {
        color: #7a7a7a;
        font-style: italic;
        font-size: 13px;
        margin-top: 1px;
        margin-bottom: -3px;
      }
      .ant-form-item-with-help {
        margin-bottom: 6px;
      }
      .form-title-split {
        margin-top: 16px;
        padding-bottom: 6px;
        margin-bottom: 15px;
        border-bottom: 1px solid lighten(@borderColor, 5%);
      }
    }

    .ant-table-wrapper {
      .ant-spin-nested-loading > div > .ant-spin {
        min-height: 420px;
        max-height: 490px;
      }
    }
  
    .avatar-uploader > .ant-upload {
      width: 118px;
      height: 118px;
    }
  }
}

.form-title-split {
  margin-top: 16px;
  padding-bottom: 6px;
  margin-bottom: 15px;
  border-bottom: 1px solid lighten(@borderColor, 5%);
}

.status-tag {
  @tagColor: #666;
  --tagColor: @tagColor;
  --tagColorLight: lighten(@tagColor, 33%);
  --tagColorDarken: darken(@tagColor, 25%);

  position: relative;
  display: inline-block;
  background-color: var(--tagColorLight, @tagColor);
  border: 1px solid var(--tagColor, @tagColor);
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 5px 7px 4px 19px;
  border-radius: 7px;
  color: var(--tagColorDarken, @tagColor);
  cursor: default;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 5px;
    margin-top: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--tagColor, @tagColor);
    overflow: hidden;
    cursor: default;
  }

  &__s01 {
    @tagColor: #e82a2c;
    --tagColor: @tagColor;
    --tagColorLight: lighten(@tagColor, 33%);
    --tagColorDarken: darken(@tagColor, 34%);
  }
  &__s02 {
    @tagColor: #f6af33;
    --tagColor: @tagColor;
    --tagColorLight: lighten(@tagColor, 33%);
    --tagColorDarken: darken(@tagColor, 34%);
  }
  &__s03 {
    @tagColor: #09609c;
    --tagColor: @tagColor;
    --tagColorLight: lighten(@tagColor, 45%);
    --tagColorDarken: darken(@tagColor, 24%);
  }
  &__s04 {
    @tagColor: #33af19;
    --tagColor: @tagColor;
    --tagColorLight: lighten(@tagColor, 35%);
    --tagColorDarken: darken(@tagColor, 27%);
  }
}

.base-table {
  .ant-table table {
    table-layout: fixed;
  }
  .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
    width: 100%;
  }
  .ant-table .ant-table-title {
    padding: 10px 11px;
  }
  .ant-table .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th {
    background-color: #f2f2f2;
    padding: 8px 7px;

    .th-small-label {
      font-size: 12px;
      letter-spacing: -0.2px;
      margin-right: -2px;
    }

    .ant-table-column-sorter {
      margin-left: 5px;
    }
  }
  .ant-table-thead > tr > th .anticon-filter,
  .ant-table-thead > tr > th .ant-table-filter-icon {
    margin-left: 5px;
  }
  .ant-table-thead > tr > th .ant-table-column-sorter + .anticon-filter {
    margin-left: 2px;
  }
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-footer {
    padding: 8px 7px;

    &.td-danger {
      background-color: lighten(#ac1a22, 30%);
    }
    &.td-dark-danger {
      background-color: lighten(#ac1a22, 20%);
    }
    &.td-success {
      background-color: darken(#e1ffdb, 5%);
    }
    &.td-dark-success {
      background-color: darken(#e1ffdb, 12%);
    }
  }
  .ant-table-tbody > &--row {
    cursor: default;
    > td {
      font-size: 13px;
    }
    &__odd {
      background-color: #fafafa;
    }
    &__danger {
      background-color: lighten(#ac1a22, 52%);

      &:hover > td {
        background-color: lighten(#ac1a22, 56%) !important;
      }
    }
    &__invalid {
      background-color: lighten(#f4c133, 35%);

      &:hover > td {
        background-color: lighten(#f4c133, 39%) !important;
      }
    }
    &__sucess {
      background-color: lighten(#33f477, 35%);

      &:hover > td {
        background-color: lighten(#33f477, 39%) !important;
      }
    }
    &__selected {
      background-color: #e1ffdb;
    }
    &__info {
      background-color: #e6f7ff;
      
      &:hover > td {
        background-color: lighten(#e6f7ff, 3%) !important;
      }
    }
    &__disabled {
      background-color: #fdfdfd !important;
      color: #bebebe !important;
      cursor: default !important;
    }
    .actions {
      .ant-btn {
        padding-left: 6px;
        padding-right: 6px;
      }
      .ant-btn > .anticon + span,
      .ant-btn > span + .anticon {
        margin-left: 4px;
      }
      .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 4px;
      }
    }
  }

  &__compact {
    .ant-table .ant-table-thead > tr > th,
    .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
    .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 5px !important;
      margin-right: -2px;
    }
    .ant-table .ant-table-thead > tr > th.td-margem,
    .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.td-margem,
    .ant-table-middle
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th.td-margem,
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.td-margem {
      background-color: darken(#fcfae0, 10%);
    }
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
    .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
    .ant-table-footer {
      font-size: 11px;
      padding: 6px 4px !important;
    }
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td.td-input,
    .ant-table-middle
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body
      > table
      > .ant-table-tbody
      > tr
      > td.td-input {
      padding: 0 !important;
      background-color: lighten(#fcfae0, 3%);

      > input {
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        height: 28px;
        min-height: 100% !important;
        margin: 0;
      }
      > a {
        display: block;
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        border: 0;
        background-color: transparent;
        height: 28px;
        min-height: 100% !important;
        width: 100%;
        margin: 0;
      }
    }
    .ant-table-footer {
      padding: 0;
    }
  }

  &__loading {
    min-height: 250px;
  }

  .wrapped-value {
    min-width: 100%;
  }

  .filter-dropdown {
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  }
}

.ant-popover-title {
  text-align: center;
}

.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-overflow {
  overflow: hidden;
}
.text-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-modal-default {
  .ant-modal-close-x {
    width: 46px;
    height: 46px;
    line-height: 46px;
  }
  .ant-modal-body {
    padding: 16px 20px;
  }
  .ant-modal-header,
  .ant-modal-footer {
    padding: 13px 20px;
  }
  .ant-form {
    .ant-form-item {
      padding-bottom: 0;
      margin: 0 0 10px;
    }
    .ant-form-item-label {
      padding-bottom: 3px;
    }
  }
}

.dblock {
  display: block !important;
}

@keyframes crescer
{
  0% { font-size: 30px; }
  50% { font-size: 20px; }
  100% { font-size: 40px; }
}

.spinColorWhite i {
  background-color: white;
}

.history-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;