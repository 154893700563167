.carga-editor {
  &-screen {
    height: ~"calc(100vh - 75px)";
    margin: -18px -28px;

    .ant-spin-nested-loading {
      height: 100%;
    }

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &--content {
    flex: 1;
    padding: 12px 13px 8px;
    overflow: auto;

    .carga-subtitle {
      font-size: 16px;
      line-height: 27px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
      cursor: default;

      &__view {
        margin: 21px 0 9px;
      }
    }

    .ant-calendar-picker {
      width: 100%;
    }

    .ant-input-sm {
      padding: 3px 7px;
      height: 29px;
    }

    .ant-input-affix-wrapper .ant-input-suffix {
      right: 10px;
    }
  }

  &--footer {
    border-top: 1px solid @borderColor;
    padding: 10px;

    .footer-label {
      float: left;
      font-size: 15px;
      padding-right: 7px;
      cursor: default;
    }
    .footer-label + .footer-label {
      border-left: 1px solid @borderColor;
      padding-left: 7px;
    }
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;