.kit-table {
  .ant-table-tbody > tr:not(.kit-table--row__disabled):hover > td {
    background: #edf8fc !important;
  }

  .ant-checkbox + span {
    font-size: 12px;
    padding-left: 4px;
    padding-right: 0;
  }

  .input-error {
    background-color: #ffeaea;
    border-color: #be2126;

    &.ant-input-disabled {
      background-color: #f9d4d4;
      border-color: #e82a2c;
    }
  }

  &__compact {
    .ant-table-tbody > .kit-table--row__selected > td.td-input {
      background-color: darken(#e1ffdb, 5%) !important;
    }
    .ant-table-middle
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body
      > table
      > .ant-table-tbody
      > tr
      > td.td-desc {
      padding: 0 !important;
      vertical-align: middle;
    }
  }
}

.meta-kit-table-wrapper {
  margin-top: 15px;

  > .ant-modal-title {
    margin-bottom: 6px;
    text-align: center;
    cursor: default;
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;