.update-produto-modal {
  .form-produto-modal {
    .produto-tag-group {
      margin-top: 8px;
    }
    .input-campanha {
      background-color: #f0ffed;
      border-color: #33af1a;

      &.ant-input-disabled {
        background-color: #f7fcf4;
        border-color: #68d350;
      }
    }
    .input-error {
      background-color: #ffeaea;
      border-color: #be2126;

      &.ant-input-disabled {
        background-color: #f9d4d4;
        border-color: #e82a2c;
      }
    }
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;