.pedido-editor {
  &-screen {
    height: ~"calc(100vh - 75px)";
    margin: -18px -28px;

    .ant-spin-nested-loading {
      height: 100%;
    }

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &--header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid @borderColor;
    padding: 12px;
    min-height: 57px;

    .ant-btn {
      padding: 0 9px;
    }

    .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      margin-left: 4px;
    }

    .ant-steps {
      padding: 0 12px;
      cursor: default;

      .ant-steps-item-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 30px;
        margin-right: 7px;
      }
      .ant-steps-item-title {
        font-size: 15px;
        line-height: 30px;
        padding-right: 12px;
        letter-spacing: -0.5px;
      }
    }
  }

  &--content {
    flex: 1;
    padding: 12px 13px 8px;
    overflow: auto;

    .ant-alert .status-tag {
      margin: 5px 0 6px -2px;
    }

    .ant-form-vertical .ant-form-item-label {
      padding: 0 0 4px !important;

      label {
        font-size: 13px;
      }
    }

    .ant-calendar-picker {
      width: 100%;
    }

    .ant-input-sm {
      padding: 3px 7px;
      height: 29px;
    }

    .ant-input-affix-wrapper .ant-input-suffix {
      right: 10px;
    }
  }

  &--footer {
    border-top: 1px solid @borderColor;
    padding: 8px 10px 0;
  }
}

@media (min-width: 1152px) {
  .pedido-editor--content .ant-form-vertical .ant-form-item {
    padding-bottom: 10px;
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;