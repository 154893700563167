.full-table {
  &--search-form {
    margin-top: -3px;
    margin-bottom: 9px;

    .ant-form-item {
      margin-right: 7px;

      .ant-form-item-label label:after {
        margin: 0 4px 0 1px;
      }
    }
    .ant-form-item + .ant-form-item {
      .ant-form-item-label {
        margin-left: 5px;
      }
    }
  }
  &--actions {
    .ant-btn + .ant-btn {
      margin-left: 6px;
    }
  }
  .ant-pagination-simple {
    .ant-pagination-simple-pager input {
      padding: 0 4px;
      margin-right: 1px;
    }
    .ant-pagination-total-text,
    .ant-pagination-simple-pager {
      height: 23px;
      line-height: 23px;
    }
    .ant-pagination-slash {
      margin-right: 3px;
    }
  }
  .ant-table-pagination {
    margin: 16px 0 8px;
  }
  .ant-table table {
    table-layout: fixed;
  }
  .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
    width: 100%;
  }
  .ant-table .ant-table-title {
    padding: 10px 11px;
  }
  .ant-table .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    background-color: #f2f2f2;
    padding: 8px 6px;

    .th-small-label {
      font-size: 12px;
      letter-spacing: -0.2px;
      margin-right: -2px;
    }

    .ant-table-column-sorter {
      margin-left: 5px;
    }

    &.ant-table-selection-column {
      padding: 5px 3px;
      min-width: 36px;
      width: 36px;
    }
  }
  .ant-table-thead > tr > th .anticon-filter,
  .ant-table-thead > tr > th .ant-table-filter-icon {
    margin-left: 5px;
  }
  .ant-table-thead > tr > th .ant-table-column-sorter + .anticon-filter {
    margin-left: 2px;
  }
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-footer {
    padding: 8px 6px;
  }
  .ant-table .ant-table-thead > tr > .ant-table-expand-icon-th,
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > .ant-table-expand-icon-th,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > .ant-table-row-expand-icon-cell {
    padding: 6px;
    min-width: 36px;
    width: 36px;
  }
  
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: unset !important; /* Remove o estilo de fundo da linha selecionada */
    color: unset !important; /* Remove as alterações de cor */
  }
  .ant-table-tbody > tr:hover > td {
    background: #edf8fc;
    
    .ranking-table {
      background-color: red !important;
    }
  }
  &--row {
    &__odd {
      background-color: #fafafa;
    }
    &__danger {
      background-color: lighten(#ac1a22, 52%);
    }
    &__warning {
      background-color: #f7ea62;
    }
    &__success {
      background-color: #c2f5b9;
    }
    &__success-light {
      background-color: lighten(#c2f5b9, 10%);
    }
    > td {
      font-size: 13px;
    }
    .pedido-status-column {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      vertical-align: middle;
    }
    .actions {
      .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 5px;
      }
    }
  }

  &__compact {
    .ant-table .ant-table-thead > tr > th,
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 7px 5px;
      margin-right: -2px;

      &.ant-table-selection-column {
        padding: 4px 2px;
        min-width: 34px;
        width: 34px;
      }
    }
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
    .ant-table-footer {
      font-size: 11px;
      padding: 6px 5px;

      &.pedido-status-column {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        vertical-align: middle;
      }
    }
  }

  &--more-info {
    font-size: 13px;
    line-height: 18px;
    padding: 0 2px;

    p {
      margin: 0 0 2px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  &--inline-extra {
    margin-top: 3px;
    font-size: 12px;
    line-height: 16px;
  }

  .wrapped-value {
    min-width: 100%;
  }

  .filter-dropdown {
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;