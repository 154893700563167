.exportable-modal {
  .ant-modal-header {
    padding: 9px 16px;
    cursor: default;
  }
  .ant-modal-body {
    padding: 14px 17px;

    .ant-progress {
      margin-bottom: 12px;
    }
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;