.custom-tooltip {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    font-family: sans-serif;
}

.green {
    background: radial-gradient(circle at right bottom, rgba(52, 235, 143, .4) 30%, rgba(52, 235, 143, 1) 50%);
}

.red {
    background: radial-gradient(circle at right bottom, rgba(230, 106, 106, .4) 30%, rgba(230, 106, 106, 1) 50%);
}