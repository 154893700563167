.app-login {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #0a8dcd url(/img/bg_login_nexxus.png) no-repeat top center;
  width: 100%;
  min-height: 100vh;

  main {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 590px;
  }

  footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0f73b9;
    border-top: 3px solid #127fc3;
    color: #fff;
    text-align: center;
    height: 52px;
    cursor: default;

    p {
      margin: 0;
      padding: 0;
      font-size: 11px;
    }

    a {
      font-size: 13px;
      color: #fff;

      &:hover {
        color: #ccc;
      }
    }

    .footer-crop {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 1024px;
      margin: 0 auto;
    }
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;