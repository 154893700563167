.carga-modal {
  .ant-modal-header {
    padding: 10px 16px;
    cursor: default;
  }
  .ant-modal-body {
    padding: 15px 16px;
  }
  .ant-modal-close-x {
    width: 43px;
    height: 43px;
    line-height: 43px;
    cursor: pointer;
  }
  .form-carga-toolbar + .carga-table-wrapper {
    margin-top: 6px;
  }
}

.carga-table {
  .ant-table-tbody > tr:not(.carga-table--row__disabled):hover > td {
    background: #edf8fc !important;
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;