.kit-editor-modal {
  .ant-modal-header {
    padding: 10px 16px;
    cursor: default;
  }
  .ant-modal-body {
    padding: 15px 16px;
  }
  .ant-modal-close-x {
    width: 43px;
    height: 43px;
    line-height: 43px;
    cursor: pointer;
  }
  .kit-table-wrapper {
    .ant-form {
      display: flex;
    }
    .ant-form-inline .ant-form-item {
      display: block;
      margin: 0;

      .ant-form-item-control-wrapper {
        display: block;
      }
    }
    .ant-form-inline .ant-form-item + .ant-form-item {
      margin-left: 9px;
    }
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;