.form-produto {
  padding: 12px 13px 0;
  border-bottom: 1px solid #ececec;

  .input-campanha {
    background-color: #f0ffed;
    border-color: #33af1a;

    &.ant-input-disabled {
      background-color: #f7fcf4;
      border-color: #68d350;
    }
  }

  .input-error {
    background-color: #ffeaea;
    border-color: #be2126;

    &.ant-input-disabled {
      background-color: #f9d4d4;
      border-color: #e82a2c;
    }
  }

  .ant-form-item-label {
    padding: 0 0 4px !important;

    label {
      font-size: 13px;
    }
  }

  .ant-input-sm {
    padding: 3px 7px;
    height: 29px;
  }

  .ant-input-affix-wrapper .ant-input-suffix {
    right: 10px;
  }
}

@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;