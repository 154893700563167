.ant-carousel .slick-dots li button::before {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: #333;
  font-size: 15px;
  font-weight: bold;
}

.ant-carousel .slick-dots li {
  counter-increment: dot-counter;
}

.ant-carousel .slick-dots li button::before {
  content: counter(dot-counter);
}

.ant-carousel .slick-dots li button {
  background-color:#333;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color:#333;
}

.image-container {
  width: 70vw;
  height: 73vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.image-container img {
  max-width: 100%;
  /* max-height: 100%; */
  object-fit: contain;
  display: block; /* Garante que o comportamento do flexbox funcione corretamente */
  margin: auto; /* Garante que a imagem seja centralizada mesmo com o scroll */
}

.left100 {
    left: 100px !important;
}